import CoverSectionForRenewedPolicyPart2 from './CoverSectionForRenewedPolicyPart2';
import DeadlineForNotifyingSection from './DeadlineForNotifyingSection';
import DeducibleSection from './DeducibleSection';
import HealthCareGuaranteeSectionPart1 from './HealthCareGuaranteeSectionPart1';
import HealthCareGuaranteeSectionPart2 from './HealthCareGuaranteeSectionPart2';
import HowToUseSection from './HowToUseSection';
import LimitationsSection from './LimitationsSection';

const RenewedPolicyPage3 = ({ data }) => {
  return (
    <>
      <CoverSectionForRenewedPolicyPart2 />
      <HealthCareGuaranteeSectionPart1 />
      <HealthCareGuaranteeSectionPart2 />
      <HowToUseSection data={data} />
      <DeadlineForNotifyingSection />
      <DeducibleSection data={data} />
      <LimitationsSection />
    </>
  );
};

export default RenewedPolicyPage3;
