import ComplainSectionForRenewedPolicyPart1 from './ComplainSectionForRenewedPolicyPart1';
import InformationSectionRenewedPolicyPart2 from './InformationSectionRenewedPolicyPart2';

const RenewedPolicyPage6 = () => {
  return (
    <>
      <InformationSectionRenewedPolicyPart2 />
      <ComplainSectionForRenewedPolicyPart1 />
    </>
  );
};

export default RenewedPolicyPage6;
