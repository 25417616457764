import styled from 'styled-components';

const ChangesInInsuranceSection = () => {
  return (
    <>
      <h2>Endringer i deres helseforsikring</h2>
      <p>
        Dere har nå vært kunde hos oss i snart ett år. Vi sender derfor deres
        nye forsikringsbevis for kommende periode. Vi ønsker også å benytte
        anledningen til å fortelle dere om endringene vi har gjort i
        forsikringen:
      </p>
      <ul>
        <li>
          <Highlight>Fortsettelsesforsikring</Highlight>
          <br />
          Vi tilbyr ikke lenger fortsettelsesforsikring til gruppemedlemmer
          dersom forsikringen sies opp av forsikringsgiver eller
          grupperepresentanten.
        </li>
        <li>
          <Highlight>Krav om henvisning</Highlight>
          <br />I tilfeller hvor vilkårene krever at det skal foreligge
          henvisning, må henvisningen være utstedt av henviser med
          henvisningsfullmakt, og den må gjelde et forhold som er omfattet av
          forsikringsvilkårene. Henvisende lege eller annen henviser må utøve
          sin virksomhet i Norden. Henvisningen må være utstedt i
          forsikringstiden og kan ikke være eldre enn 12 måneder.
        </li>
      </ul>
      <p>
        <Highlight>Har dere blitt flere i bedriften?</Highlight>
        <br />
        Ta kontakt med oss så sørger vi for å oppdatere forsikringen slik at
        alle er dekket av forsikringen.
      </p>
      <p>Vi ser frem mot et nytt forsikringsår sammen med dere!</p>
    </>
  );
};

export default ChangesInInsuranceSection;

const Highlight = styled.span`
  color: #26dcba;
`;
