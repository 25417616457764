const InformationSectionPart2 = () => {
  return (
    <>
      <ul>
        <li>
          Forbrukertilsynet Besøksadresse: Porselensvegen 32, 3920 Porsgrunn.
          Postadresse: Postboks 2862 Kjørbekk, 3702 Skien. Tlf.: 23 400 600
          https://www.forbrukertilsynet.no/
        </li>
        <li>
          Integritetsbeskyttelsesmyndigheten
          (Integritetsbeskyttelsesmyndigheten, Box 8114, 104 20 Stockholm
          imy@imy.se) og Datatilsynet (https://www.datatilsynet.no/) hva gjelder
          behandling av personopplysninger.
        </li>
      </ul>
    </>
  );
};

export default InformationSectionPart2;
