const CoverSectionForRenewedPolicyPart2 = () => {
  return (
    <ul>
      <li>Behandling hos psykolog</li>
      <li>Behandling hos fysioterapeut/kiropraktor/naprapat</li>
      <li>Etterbehandling og medisinsk rehabilitering</li>
      <li>Rehabiliteringsplan</li>
      <li>Second opinion – ny medisinsk vurdering</li>
    </ul>
  );
};

export default CoverSectionForRenewedPolicyPart2;
