import ApplyWhereSection from './ApplyWhereSection';
import ApplyToWhoSection from './ApplyToWhoSection';
import ApplyWhenSection from './ApplyWhenSection';
import IntroductoryInformationSection from './IntroductoryInformationSection';
import CoverSectionForRenewedPolicyPart1 from './CoverSectionForRenewedPolicyPart1';

const RenewedPolicyPage2 = ({ data }) => {
  return (
    <>
      <IntroductoryInformationSection />
      <ApplyToWhoSection />
      <ApplyWhenSection />
      <ApplyWhereSection />
      <CoverSectionForRenewedPolicyPart1 />
    </>
  );
};

export default RenewedPolicyPage2;
