const BackgroundAndPurposeSection = () => {
  return (
    <section>
      <h2>Bakgrunn og formål</h2>
      <p>
        Forsikringsgiveren leverer i denne avtalen forsikringsproduktet
        helseforsikring. Det er en kollektiv forsikring, hvor rettigheter og
        plikter for medlemmene av gruppen blir fastlagt gjennom denne avtalen
        som forsikringstakeren inngår til fordel for medlemmene.
      </p>
    </section>
  );
};

export default BackgroundAndPurposeSection;
