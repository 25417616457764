const CongratulationsSection = () => {
  return (
    <section>
      <h2>Gratulerer!</h2>
      <p>
        Arbeidsgiveren din har tegnet forsikring for deg. Forsikringen er en
        gruppeforsikring. De personene som omfattes av forsikringen er ansatte i
        den bedriften som har tegnet forsikringen. Disse personene er
        gruppemedlemmene og dermed de forsikrede. Når vi i fortsettelsen skriver
        "du", "deg" og "din", mener vi personen (gruppemedlemmet) som
        forsikringen gjelder for.
      </p>
      <p>
        Til grunn for forsikringen ligger en gruppeavtale. Gruppemedlemmene er
        direkte tilknyttet forsikringen på grunn av gruppeavtalen. Gruppeavtalen
        inngås mellom forsikringsgiveren og forsikringstaker, det vil si den
        bedriften som har tegnet forsikringen.
      </p>
      <p>
        Forsikringen tegnes for ett år om gangen og fornyes automatisk om den
        ikke sies opp av forsikringsgiveren eller forsikringstaker.
        Forsikringsgiveren har rett til å endre premie- og forsikringsvilkårene
        for forsikringen før den årlige fornyelsen. Det er din arbeidsgiver som
        betaler for forsikringen din. Forsikringen klassifiseres skattemessig
        som en fordel og du som ansatt skattlegges for dette etter gjeldende
        satser.
      </p>
    </section>
  );
};

export default CongratulationsSection;
