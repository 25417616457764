const InformationSectionRenewedPolicyPart2 = () => {
  return (
    <>
      <ul>
        <li>
          Finansinspektionen (Finansinspektionen, Box 7821, 103 97 Stockholm
          eller finansinspektionen@fi.se){' '}
        </li>
        <li>
          Finanstilsynet (Besøksadresse: Revierstredet 3, 0151 Oslo Postadresse:
          Postboks 1187 Sentrum 0107 Oslo Tlf: 22 93 98 00 E-post:
          post@finanstilsynet.no https://www.finanstilsynet.no/)
        </li>
        <li>
          Konsumentverket (Konsumentverket, Box 48, 651 02 Karlstad eller
          konsumentverket@konsumentverket.se) og Forbrukertilsynet
          (https://www.forbrukertilsynet.no/) hva gjelder reklame og
          markedsføring.{' '}
        </li>
        <li>
          Forbrukertilsynet Besøksadresse: Porselensvegen 32, 3920 Porsgrunn.
          Postadresse: Postboks 2862 Kjørbekk, 3702 Skien. Tlf.: 23 400 600
          https://www.forbrukertilsynet.no/
        </li>
        <li>
          Integritetsbeskyttelsesmyndigheten
          (Integritetsbeskyttelsesmyndigheten, Box 8114, 104 20 Stockholm
          imy@imy.se) og Datatilsynet (https://www.datatilsynet.no/) hva gjelder
          behandling av personopplysninger.
        </li>
      </ul>
    </>
  );
};

export default InformationSectionRenewedPolicyPart2;
