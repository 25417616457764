const BenefitTaxationSection = () => {
  return (
    <>
      <h2>Fordelsbeskatning</h2>
      <p>
        Forsikringen er en fordel som gir deg rask tilgang til vurdering og
        behandling av skadene dekket av forsikringen. Det er således et
        supplement til eksisterende helsehjelp og klassifiseres skattemessig som
        en fordel. Arbeidsgiveren din må oppgi dette i kontrollinformasjonen som
        sendes til skatteetaten.
      </p>
    </>
  );
};

export default BenefitTaxationSection;
