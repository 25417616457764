const ChoiceOfLawAndDisputeSection = () => {
  return (
    <section>
      <h2>Lovvalg og tvist</h2>
      <p>
        Norsk lov gjelder for denne forsikringsavtalen. Tvister skal avgjøres
        ved norske domstoler med mindre annet er avtalt eller det er i strid med
        ufravikelige regler i gjeldende lovgivning.
      </p>
    </section>
  );
};

export default ChoiceOfLawAndDisputeSection;
