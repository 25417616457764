import BenefitTaxationSection from './BenefitTaxationSection';
import NotApplyToSectionPart1 from './NotApplyToSectionPart1';
import NotApplyToSectionPart2 from './NotApplyToSectionPart2';
import NotCoveredCostsSection from './NotCoveredCostsSection';

const RenewedPolicyPage4 = ({ data }) => {
  return (
    <>
      <NotApplyToSectionPart1 />
      <NotApplyToSectionPart2 />
      <NotCoveredCostsSection />
      <BenefitTaxationSection />
    </>
  );
};

export default RenewedPolicyPage4;
