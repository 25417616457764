const NotApplyToSectionPart1 = () => {
  return (
    <>
      <h2>Forsikringen gjelder ikke for:</h2>
      <ul>
        <li>
          Sykdom og plager eller følge av slike forhold som er blitt påvist
          eller har vist symptomer før forsikringen trer i kraft
        </li>
        <li>
          Medisinsk behandling som ikke har blitt godkjent på forhånd av
          forsikringsselskapet
        </li>
        <li>
          Akutt sykdom/plager, akutt medisinsk behandling, akutt transport og
          utgifter i forbindelse med akutt medisinsk behandling
        </li>
        <li>
          Konsultasjon hos allmennlege og spesialist i allmennmedisin. Digital
          legetjeneste eller videokonsultasjon hos en leverandør Avonova har
          avtale med er likevel omfattet.
        </li>
        <li>
          Forebyggende behandling, vaksinasjoner, helseundersøkelser og
          screeningundersøkelser
        </li>
        <li>Kroniske sykdommer</li>
        <li>
          Meldepliktig sykdom som er regulert av lov eller konsekvenser av en
          slik sykdom
        </li>
      </ul>
    </>
  );
};

export default NotApplyToSectionPart1;
