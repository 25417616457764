const ApplyWhereSection = () => {
  return (
    <>
      <h2>Hvor gjelder forsikringen?</h2>
      <p>
        Forsikringen gjelder for medisinsk behandling i Norge. Hvis du har tenkt
        å bli i utlandet i mer enn 3 måneder, eller hvis du flytter fra Norge,
        må bedriften din rapportere dette til oss, og du er ikke lenger dekket
        av forsikringen.
      </p>
    </>
  );
};

export default ApplyWhereSection;
