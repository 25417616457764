import styled from 'styled-components';
import AvonovaLogo from '../images/Avonova_logo.png';

const PageHeader = () => {
  return (
    <PageHeaderContainer>
      <img src={AvonovaLogo} alt='Avonova' />
      <hr />
    </PageHeaderContainer>
  );
};

export default PageHeader;

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  margin-bottom: 2rem;

  @media print {
    width: 100%;
    margin: 0 8mm;
    margin-top: 1mm;
    padding: 0;
  }

  img {
    width: 15rem;
    height: auto;
  }

  hr {
    height: 0.0625rem;
    border-width: 0;
    background-color: #d3d3d3;
    margin-top: 1rem;
  }

  span {
    margin-left: auto;
    font-size: 0.5rem;
  }
`;
