import BenefitTaxationSection from './BenefitTaxationSection';
import NotApplyToSectionPart2 from './NotApplyToSectionPart2';
import NotCoveredCostsSection from './NotCoveredCostsSection';
import PersonalInformationSection from './PersonalInformationSection';

const NewPolicyPage4 = ({ data }) => {
  return (
    <>
      <NotApplyToSectionPart2 />
      <NotCoveredCostsSection />
      <BenefitTaxationSection />
      <PersonalInformationSection />
    </>
  );
};

export default NewPolicyPage4;
