const LimitationsSection = () => {
  return (
    <>
      <h2>Viktige begrensninger i forsikringen</h2>
      <p>
        I forsikringen finnes det ulike begrensninger. Forsikringsvilkårene sier
        blant annet at forsikringen ikke gjelder i visse situasjoner. Nedenfor
        er noen av de viktigste begrensningene innen forsikringen. Se vilkår for
        fullstendig oversikt.
      </p>
    </>
  );
};

export default LimitationsSection;
