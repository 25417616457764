const ApplyToWhoSection = () => {
  return (
    <>
      <h2>Hvem gjelder forsikringen for?</h2>
      <p>
        Forsikringen gjelder for deg som bedriften og grupperepresentanten har
        tegnet helseforsikringen for og som er fullt arbeidsfør, mellom 18 og 70
        år og er bosatt og folkeregistrert i Norge eller har ditt hovedsakelige
        arbeidsforhold i Norge.
      </p>
    </>
  );
};

export default ApplyToWhoSection;
