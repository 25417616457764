const DeducibleSection = ({ data }) => {
  return (
    <>
      <h2>Egenandel</h2>
      <p>
        {data.answers.excessLevel === '0'
          ? 'Forsikringen har ingen egenandel. '
          : `Forsikringen har en egenandel på ${data.answers.excessLevel} NOK. `}
        Hvis selskapet ditt har tegnet forsikring med egenandel, betaler du
        dette når du henvises til første time via Behandlingsplanleggingen.
      </p>
    </>
  );
};

export default DeducibleSection;
