const ApplyWhenSection = () => {
  return (
    <>
      <h2>Når gjelder forsikringen?</h2>
      <p>
        Forsikringen gjelder for forsikringstilfeller som inntreffer i løpet av
        forsikringsperioden, dvs den tid som forsikringen er i kraft.
      </p>
    </>
  );
};

export default ApplyWhenSection;
