import styled from 'styled-components';

const PolicyInformationSection = ({ data }) => {
  const invoiceInterval = numberOfInstallments => {
    switch (numberOfInstallments) {
      case '1':
        return 'År';
      case '2':
        return 'Halvår';
      case '4':
        return 'Kvartal';
      case '12':
        return 'Månad';
      default:
        return 'År';
    }
  };

  return (
    <Section>
      <p>
        Avtale: {data.policyNumber}
        <br />
        Avtalepart: {data.answers.companyName} {data.answers.companyReference}
        <br />
        Grupperepresentant: {data.answers.groupRepTitle}{' '}
        {data.answers.groupRepName}, {data.answers.groupRepEmail},{' '}
        {data.answers.groupRepPhone}
        <br />
        Avtaleperiode: {new Date(data.start).toLocaleDateString('nb-NO')} -{' '}
        {new Date(data.end).toLocaleDateString('nb-NO')}
        <br />
        Hovedforfallsdato: {new Date(data.end).toLocaleDateString('nb-NO')}
        <br />
        Forsikret gruppe: {data.answers.companyToInsureDescription}
        <br />
        Antall forsikrede: {data.answers.numberOfEmployees}
        <br />
        Premie per forsikret og år:{' '}
        {(
          data.policyPremium.totalPremiumExcludingTax /
          Number(data.answers.numberOfEmployees)
        ).toLocaleString('sv-SE', { maximumFractionDigits: 0 })}{' '}
        kr
        <br />
        Faktureringsintervall:{' '}
        {invoiceInterval(data.answers.numberOfInstallments)}
        <br />
      </p>
      <InfoSection>
        Du har rett til denne informasjonen i henhold til loven, og det er
        viktig at du leser denne informasjonen sammen med andre dokumenter som
        gjelder forsikringen. Ta gjerne vare på denne informasjonen. Du får de
        fulle forsikringsvilkårene via arbeidsgiveren din eller på vår
        hjemmeside <Underlined>www.avonova.no</Underlined>.
      </InfoSection>
    </Section>
  );
};

export default PolicyInformationSection;

const Section = styled.section`
  color: red;
  border-top: solid 0.0625rem grey;
  border-bottom: solid 0.0625rem grey;
  padding: 1rem 0;
  margin-bottom: 2rem;
`;

const InfoSection = styled.p`
  font-style: italic;
`;

const Underlined = styled.span`
  text-decoration: underline;
`;
