import ChangesInInsuranceSection from './ChangesInInsuranceSection';
import PolicyInformationSection from './PolicyInformationSection';

const RenewedPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Helseforsikring
        <br /> Forsikringsbevis
      </h1>
      <p>2024</p>
      <PolicyInformationSection data={data} />
      <ChangesInInsuranceSection />
    </>
  );
};

export default RenewedPolicyPage1;
