const ContractSection = ({ data }) => {
  return (
    <>
      <h2>Avtalen</h2>
      <p>
        Eir Försäkring AB, org.nr. 559166-0617 (forsikringsgiveren) og{' '}
        {data.answers.companyName} {data.answers.companyReference}{' '}
        (grupperepresentanten, også arbeidsgiveren og forsikringstakeren) har
        inngått følgende gruppeavtale som omfatter helseforsikring.
        Grupperepresentanten er ansvarlig for at innholdet i denne gruppeavtalen
        ikke bryter med de bestemmelsene som til enhver tid må være gjeldende i
        henhold til interne regler hos arbeidsgiveren eller eventuelt
        eksisterende kollektivavtale som arbeidsgiveren er bundet av.
      </p>
      <p>
        Denne gruppeavtalen, forsikringens vilkår, forsikringsavtaleloven (FAL),
        gruppemedlemmets forsikringsbevis og gjeldende lov gjelder for denne
        forsikringen.
      </p>
      <p>
        Denne avtalen har betegnelsen {data.answers.companyToInsure}{' '}
        {data.answers.companyToInsureDescription}.
        <br />
        Grupperepresentant: {data.answers.groupRepTitle}{' '}
        {data.answers.groupRepName}, {data.answers.groupRepEmail},{' '}
        {data.answers.groupRepPhone}
        <br />
        Denne avtalen har følgende avtaleperiode:{' '}
        {new Date(data.startDate).toLocaleDateString('nb-NO')} -{' '}
        {new Date(data.endDate).toLocaleDateString('nb-NO')}
        <br />
        Forsikret gruppe: {data.answers.companyToInsureDescription}
        {data.answers.previousInsurer &&
          data.answers.previousInsurer.trim().length > 0 && (
            <>
              <br />
              Overtagelse fra forhenværende forsikringsgiver:{' '}
              {data.answers.previousInsurer}
              <br />
              Startdato forhenværende forsikringsgiver:{' '}
              {new Date(
                data.answers.previousInsurerStartDate
              ).toLocaleDateString('nb-NO')}
            </>
          )}
      </p>
    </>
  );
};

export default ContractSection;
