const DeadlineForNotifyingSection = () => {
  return (
    <section>
      <h2>Frist for å gi melding om forsikringstilfellet</h2>
      <p>
        Fristen for å gi melding om forsikringstilfellet er ett år fra du fikk
        kunnskap om de forhold som begrunner kravet. Dersom fristen ikke
        overholdes, vil du kunne miste retten til erstatning.
      </p>
    </section>
  );
};

export default DeadlineForNotifyingSection;
