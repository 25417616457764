const HowToUseSection = ({ data }) => {
  return (
    <>
      <h2>Hvordan du bruker forsikringen</h2>
      <p>
        Hvis du har behov for å melde inn sak gjøres dette gjennom:{' '}
        {data.answers.productType === 'Employee reports' &&
          'Digital Direkteregistrering av den enkelte ansatte på hjemmesiden https://www.avonova.no/meld-skade-her. '}
        {data.answers.productType === 'Only manager/ HR reports' &&
          'Digital registrering av HR/Leder via skjema på hjemmesiden (www.avonova.no). '}
        Hør med din leder eller se i deres gruppeavtale hva som gjelder for
        deres avtale.
      </p>
    </>
  );
};

export default HowToUseSection;
