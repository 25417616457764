import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { configureAmplify } from '@eir/aws-authorization';

import GroupContractNorwayView from './views/GroupContractNorway/GroupContractNorwayView';
import InsuranceLetterNorwayView from './views/InsuranceLetterNorway/InsuranceLetterNorwayView';
import './styles/fonts.css';
import GlobalStyle from './styles/globalStyle';

configureAmplify(
  {
    endpointName: 'AvonovaAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path='/q/:quoteId' component={GroupContractNorwayView} />
        <Route
          exact
          path='/p/:policyId'
          component={InsuranceLetterNorwayView}
        />
      </Switch>
    </Router>
  );
};

export default App;
