const ContractPeriodAndTerminationSection = () => {
  return (
    <section>
      <h2>Avtaletid og oppsigelse</h2>
      <p>
        Denne avtalen trer i kraft og forsikringsgivers ansvar innledes når
        avtaleperioden som angis i denne avtalen starter. Den innledende
        avtaletiden er på ett år, men kan når som helst sies opp med en måneds
        varsel. Avtalen fornyes deretter automatisk med ett år om gangen om den
        ikke sies opp av forsikringsgiveren eller forsikringstaker. Om
        gruppeavtalen sies opp av forsikringstaker opphører forsikringen for
        samtlige gruppemedlemmer på det tidspunktet som angis i oppsigelsen, men
        tidligst en måned etter at oppsigelsen er mottatt av Avonova.
      </p>
    </section>
  );
};

export default ContractPeriodAndTerminationSection;
