const HealthCareGuaranteeSectionPart1 = () => {
  return (
    <p>
      Garantien gjelder ikke hvis den forsikrede ikke kan opereres av medisinske
      årsaker, uteblir fra den bestilte behandlingen, nekter bestilt tid, eller
      etter avtale går med på å bestille et senere tidspunkt for behandling. Om
      vi ikke kan leve opp til behandlingsgarantien gis rabatt på forsikringen
      og den krediteres kunden.
    </p>
  );
};

export default HealthCareGuaranteeSectionPart1;
