const ComplainSectionForRenewedPolicyPart2 = () => {
  return (
    <section>
      <p>
        Som ved andre tvister kan du også gå til en domstol. Ta kontakt med
        nærmeste tingrett om du vil vite mer eller se www.domstol.no.
      </p>
      <p>
        Forbrukerrådet kan også gi noe veiledning i forbindelse med klage på
        forsikring.
        <br /> HJEMMESIDE:
        https://www.forbrukerradet.no/forside/okonomi-og-betaling/forsikring/
        <br />
        For ytterligere informasjon om klagemuligheter, se forsikringsvilkårene
        eller vår hjemmeside
        www.avonova.no/helseforsikring/helseforsikringsklager.
      </p>
    </section>
  );
};

export default ComplainSectionForRenewedPolicyPart2;
