const HealthCareGuaranteeSectionPart1 = () => {
  return (
    <>
      <h2>Garanti for medisinsk behandling</h2>
      <p>
        Forsikringen inneholder en garanti for medisinsk behandling. Fra det
        tidspunktet forsikringstilfellet ditt er godkjent av
        forsikringsselskapet, det forutsettes at det dekkes av forsikringen og
        er medisinsk begrunnet, har du en garanti:
      </p>
      <ul>
        <li>
          en første medisinsk rådgivning av medisinsk autorisert personell innen
          syv arbeidsdager, og
        </li>
        <li>
          operasjonen skal finne sted innen 20 arbeidsdager fra operasjonen er
          godkjent av forsikringsgiveren.
        </li>
      </ul>
    </>
  );
};

export default HealthCareGuaranteeSectionPart1;
