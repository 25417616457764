const LiabilityPeriodSectionAndConnectingGroupMembersSection = () => {
  return (
    <section>
      <h2>Ansvarstid og tilknytning av gruppemedlemmer</h2>
      <p>
        Forsikringsgiverens ansvar i forbindelse med forsikringen innledes når
        avtaleperioden som angis i denne avtalen starter. Gruppemedlemmene som
        på det tidspunktet oppfyller tilslutningskravene som er angitt i
        forsikringsvilkårene, er tilknyttet forsikringen og dekkes dermed av
        forsikringen. Forsikringsgiveren overtar ansvar for gruppemedlemmer som
        tilknyttes senere fra og med dagen etter dette er formidlet til Avonova.
        Endringer i antall gruppemedlemmer skal rapporteres til Avonova
        fortløpende. Premien kan deretter justeres etter antall forsikrede i
        etterkant.
      </p>
      <p>
        Hvis forsikringstaker ikke betaler premien innen den frist som fremgår
        av betalingsvarselet, har forsikringsgiveren rett til å si opp
        forsikringen.
      </p>
    </section>
  );
};

export default LiabilityPeriodSectionAndConnectingGroupMembersSection;
