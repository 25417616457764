import BackgroundAndPurposeSection from './BackgroundAndPurposeSection';
import ContractSection from './ContractSection';
import GroupRepresentativesAndGroupMembersSection from './GroupRepresentativesAndGroupMembersSection';

const Page1 = ({ data }) => {
  return (
    <>
      <h1>Gruppeavtale for gruppeforsikring</h1>
      <ContractSection data={data} />
      <BackgroundAndPurposeSection />
      <GroupRepresentativesAndGroupMembersSection />
    </>
  );
};

export default Page1;
