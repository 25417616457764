import ChoiceOfLawAndDisputeSection from './ChoiceOfLawAndDisputeSection';
import ContractPeriodAndTerminationSection from './ContractPeriodAndTerminationSection';
import InsuredAmountAndPermiumsSection from './InsuredAmountAndPermiumsSection';
import LiabilityPeriodSectionAndConnectingGroupMembersSection from './LiabilityPeriodSectionAndConnectingGroupMembersSection';

const Page3 = ({ data }) => {
  return (
    <>
      <InsuredAmountAndPermiumsSection data={data} />
      <LiabilityPeriodSectionAndConnectingGroupMembersSection />
      <ContractPeriodAndTerminationSection />
      <ChoiceOfLawAndDisputeSection />
    </>
  );
};

export default Page3;
