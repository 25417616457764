const PersonalInformationSection = () => {
  return (
    <>
      <h2>Personopplysninger</h2>
      <p>
        Vi beskytter personopplysningene dine og behandler dem i samsvar med
        databeskyttelsesforordningen (GDPR) og andre gjeldende regler. For mer
        informasjon om forsikringsgiverens behandling av personopplysninger, les
        mer i forsikringsselskapets retningslinjer for personvern her: Eir
        Försäkrings ABs retningslinjer for personvern (www.eirforsakring.com).
        For mer informasjon om Avonovas behandling av personopplysninger, les
        mer i Avonovas retningslinjer for personvern her:
        https://www.avonova.no/om-avonova-helse/personvern-digitale-kanaler/
      </p>
    </>
  );
};

export default PersonalInformationSection;
