import ApplyWhereSection from './ApplyWhereSection';
import ApplyToWhoSection from './ApplyToWhoSection';
import ApplyWhenSection from './ApplyWhenSection';
import CoverSection from './CoverSection';
import HealthCareGuaranteeSectionPart1 from './HealthCareGuaranteeSectionPart1';

const NewPolicyPage2 = ({ data }) => {
  return (
    <>
      <ApplyToWhoSection />
      <ApplyWhenSection />
      <ApplyWhereSection />
      <CoverSection />
      <HealthCareGuaranteeSectionPart1 />
    </>
  );
};

export default NewPolicyPage2;
