const InformationSectionRenewedPolicyPart1 = () => {
  return (
    <>
      <h2>
        Informasjon om forsikringsgiveren, forsikringsformidleren og tilsyn m.m.
      </h2>
      <p>
        Forsikringsgiver er Eir Försäkring AB, org.nr. 559166-0617, Kungsgatan
        37, 111 56 Stockholm (forsikringsgiveren). Forsikringsgiveren kan
        kontaktes på info@eirforsakring.se eller tlf. +47 911 78 000. Webadresse
        er www.eirforsakring.com .
      </p>
      <p>
        Forsikringsformidler er Avonova Insurance AS, org.nr. 926931466,
        Postboks 4903 Nydalen, 0423 Oslo. Forsikringsformidleren kan kontaktes
        på forsikring@avonova.no.
      </p>
      <p>
        Avonova er en forsikringsformidler tilknyttet Eir Forsikring AB. Eir
        Forsikring AB er ansvarlig for rent formuestap i overensstemmelse med 4
        kap. 16§ lag om försäkringsdistribution (LFD), som kan inntreffe om
        Avonova ikke overholder sine forpliktelser (med forsett eller av
        uaktsomhet). Avonova er registrert i det norske Finanstilsynets
        virksomhetsregister, hvilket kan kontrolleres her:
        https://www.finanstilsynet.no/virksomhetsregisteret/
      </p>
      <p>
        Avonova`s ansatte som selger forsikring, får en fast månedslønn
        uavhengig av antall solgte forsikringer. Avonova handler på vegne av
        forsikringsgiveren og er etter avtale forpliktet til å distribuere
        forsikring utelukkende på vegne av forsikringsgiveren. Avonova`s
        kompensasjon for å distribuere forsikringen til forsikringsgiveren
        beregnes på grunnlag av skadeutfall og premievolum. Avonova har ingen
        kvalifisert eierandel i forsikringsgiveren, og forsikringsgiveren har
        ingen kvalifisert eierandel i Avonova.
      </p>
      <p>
        På forespørsel skal forsikringsgiveren gi informasjon om en ansatt i
        Avonova har rett til å distribuere forsikringer og om denne retten er
        begrenset til en bestemt type forsikring, en eller flere
        forsikringsklasser eller grupper av forsikringsklasser.
      </p>
      <p>
        Virksomheten til Eir Forsikring AB og Avonova er under tilsyn av
        relevante myndigheter i Sverige og Norge der de mest relevante er:
      </p>
    </>
  );
};

export default InformationSectionRenewedPolicyPart1;
