import ComplainSectionForRenewedPolicyPart2 from './ComplainSectionForRenewedPolicyPart2';

const RenewedPolicyPage7 = () => {
  return (
    <>
      <ComplainSectionForRenewedPolicyPart2 />
    </>
  );
};

export default RenewedPolicyPage7;
