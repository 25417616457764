const NotApplyToSectionPart2 = () => {
  return (
    <ul>
      <li>
        Kosmetiske behandlinger og operasjoner eller konsekvenser av dem og som
        ikke er en konsekvens av en kompenserbar sykdom eller utilsiktet skade
      </li>
      <li>
        Kontroll og behandling forårsaket av graviditet, fødsel, abort,
        sterilisering
      </li>
      <li>
        Fertilitetsundersøkelse, behandling av infertilitet, kjønnskorrigerende
        behandlinger
      </li>
      <li>
        Utredning og behandling av nevropsykiatrisk lidelse (f.eks ADHD,
        autisme)
      </li>
      <li>
        Behandling eller medikamentkostnader på grunn av fedme eller
        fedmeoperasjon
      </li>
      <li>Utredning og behandling av snorking og søvnapné</li>
      <li>Undersøkelse og behandling av tenner eller synsfeil</li>
    </ul>
  );
};

export default NotApplyToSectionPart2;
