import DeadlineForNotifyingSection from './DeadlineForNotifyingSection';
import DeducibleSection from './DeducibleSection';
import HealthCareGuaranteeSectionPart2 from './HealthCareGuaranteeSectionPart2';
import HowToUseSection from './HowToUseSection';
import LimitationsSection from './LimitationsSection';
import NotApplyToSectionPart1 from './NotApplyToSectionPart1';

const NewPolicyPage3 = ({ data }) => {
  return (
    <>
      <HealthCareGuaranteeSectionPart2 />
      <HowToUseSection data={data} />
      <DeadlineForNotifyingSection />
      <DeducibleSection data={data} />
      <LimitationsSection />
      <NotApplyToSectionPart1 />
    </>
  );
};

export default NewPolicyPage3;
