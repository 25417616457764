import InformationSectionRenewedPolicyPart1 from './InformationSectionRenewedPolicyPart1';
import PersonalInformationSection from './PersonalInformationSection';

const RenewedPolicyPage5 = () => {
  return (
    <>
      <PersonalInformationSection />
      <InformationSectionRenewedPolicyPart1 />
    </>
  );
};

export default RenewedPolicyPage5;
