const InsuredAmountAndPermiumsSection = ({ data }) => {
  return (
    <section>
      <h2>Forsikringsbeløp og premier</h2>
      <p>
        Ved inngåelsen av denne avtalen gjelder en premie på{' '}
        {(
          data.premium.monthly / Number(data.answers.numberOfEmployees)
        ).toLocaleString('nb-NO', {
          maximumFractionDigits: 0,
        })}{' '}
        NOK per forsikret og per måned. Visse avvik kan oppstå ved fakturering
        av premiebeløpet på grunn av avrunding av øre til hele kroner per
        forsikret.
      </p>
    </section>
  );
};

export default InsuredAmountAndPermiumsSection;
