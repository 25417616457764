const PartiesCommitmentsSection = () => {
  return (
    <section>
      <h2>Partenes forpliktelser</h2>
      <p>
        Forsikringsgiveren forplikter seg, i samsvar med denne gruppeavtalen,
        til å gi forsikringsytelser til alle som er ansatt hos forsikringstaker
        eller den gruppen som er spesifisert i denne gruppeavtalen.
        Forsikringsgiveren har utnevnt Avonova som samarbeidspartner og
        forsikringsformidler.
      </p>
      <p>
        Forsikringsgiveren må sørge for helseforsikring til de
        forsikringsberettigede som oppfyller kravene til å være dekket av
        forsikringen. Forsikringsgiveren eller den samarbeidspartneren som er
        utpekt av forsikringsgiveren (Avonova) skal dessuten håndtere
        forsikringsadministrasjon og kundeservice, samt levere vilkår,
        forsikringsinformasjon og informasjonsmateriell.
      </p>
      <p>
        Grupperepresentanten skal gi løpende informasjon om antall forsikrede i
        den utstrekning og omfang det er nødvendig for korrekt oppfyllelse av
        denne avtalen til forsikringsgiveren eller til den samarbeidspartneren
        som er oppnevnt av forsikringsgiveren (Avonova). Forsikringstakeren må
        sørge for at premiebetalinger gjøres mellom forsikringstaker og
        forsikringsgiveren, eller til den samarbeidspartneren som er utpekt av
        forsikringsgiveren (Avonova).
      </p>
      <p>
        Grupperepresentanten skal levere dokumenter til gruppemedlemmene og
        informere om forsikringen i samsvar med instruksjonene som gis av
        forsikringsgiveren eller av den samarbeidspartneren som er oppnevnt av
        forsikringsgiveren (Avonova).
      </p>
    </section>
  );
};

export default PartiesCommitmentsSection;
