const CoverSectionForRenewedPolicyPart1 = () => {
  return (
    <>
      <h2>Hva omfatter forsikringen?</h2>
      <p>
        Forsikringen omfatter nedenstående punkter som følge av sykdom eller
        plager som har oppstått i forsikringsperioden og der symptomene ikke har
        oppstått eller er påvist før forsikringen trer i kraft. Forsikringen
        dekker medisinsk behandling som beskrevet nedenfor, som til enhver tid
        finnes i privat regi i Norge.
      </p>
      <ul>
        <li>Helserådgivning og Behandlingsplanlegging </li>
        <li>Digital lege- og psykologtjeneste</li>
        <li>Konsultasjon hos legespesialist</li>
        <li>Operasjon og sykehusbehandling</li>
      </ul>
    </>
  );
};

export default CoverSectionForRenewedPolicyPart1;
