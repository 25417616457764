import { createGlobalStyle } from 'styled-components';

import NeuzeitOfficeSRProRegular from '../fonts/2D75BF_1_0.woff2';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'NeuzeitOfficeSRProRegular';
  src: url(${NeuzeitOfficeSRProRegular}) format('woff2');
  font-weight: 400;
  font-style: normal;
}

* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1 {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media print {
    font-size: 27pt;
    margin-bottom: 20pt;
  }
}

h2 {
  color: #26dcba;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 1rem;
  margin-bottom: 0.2rem;

  @media print {
    font-size: 13pt;
    margin-bottom: 2pt;
  }
}

p, ul {
  font-size: 1.1rem;
  color: #262626;
  line-height: 1.5;
  margin-bottom: 0.5rem;

  @media print {
    font-size: 11pt;
    margin-bottom: 8pt;
  }
}

@page {
  size: A4;
  margin: 6.35mm 6.35mm 14.46mm 6.35mm !important;
  
} 
`;

export default GlobalStyle;
