const ProductDescriptionSection = ({ data }) => {
  return (
    <section>
      <h2>Produktbeskrivelse</h2>
      <p>
        Forsikringen dekker sykdommer og plager som har oppstått i løpet av
        forsikringstiden og der symptomer ikke har oppstått eller blitt påvist
        før forsikringens startdato. Forsikringen gjelder for behandling i Norge
        av Helseforetak som Avonova har inngått avtale med. Det henvises i
        første omgang til de til enhver tid gjeldende forsikringsvilkårene for
        en fullstendig beskrivelse av forsikringens dekning.
      </p>
      <p>
        Forsikringen tegnes for ett år om gangen og fornyes automatisk hvis den
        ikke sies opp av forsikringsgiveren eller forsikringstaker.
        Forsikringsgiveren har rett til å endre premie, forsikringsvilkår og
        forsikringsbeløp for forsikringen før den årlige fornyelsen.
      </p>
      <p>
        {data.answers.excessLevel === '0'
          ? 'Forsikringen har ingen egenandel.'
          : `Forsikringen har en egenandel på 
          ${data.answers.excessLevel} NOK.`}
      </p>
      <p>
        Hvis du har behov for å sende inn et krav gjøres dette gjennom:{' '}
        {data.answers.productType === 'Employee reports' &&
          'Digital  Direkteregistrering av den enkelte ansatte på hjemmesiden (www.avonova.no).'}
        {data.answers.productType === 'Only manager/ HR reports' &&
          'Digital registrering av HR/Leder via skjema på hjemmesiden (www.avonova.no).'}
      </p>
    </section>
  );
};

export default ProductDescriptionSection;
